import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
} from "@material-ui/core";
import { DateTime } from "luxon";
import {
  FilterType,
  MUIDataTableColumn,
  MUIDataTableCustomFilterListOptions,
  MUIDataTableFilterOptions,
  MUIDataTableState,
} from "mui-datatables";
import React from "react";
import { convertString } from "../common/utils";
import { ISODateFormat, ServerMomentTimezone } from "../common/utils/date";
import { valueIsWithinRange } from "../common/utils/number";

const CustomMuiDatatableFilter = (
  filterList: MUIDataTableState["filterList"],
  onChange: (
    val: string | string[],
    index: number,
    column: MUIDataTableColumn
  ) => void,
  index: number,
  column: MUIDataTableColumn,
  filterData: MUIDataTableState["filterData"]
) => {
  if (filterData[index].length <= 5) {
    return CustomMuiDatatableFilter2(
      filterList,
      onChange,
      index,
      column,
      filterData
    );
  }

  return (
    <FormControl>
      <InputLabel htmlFor={`select-multiple-chip-${column.name}`}>
        {column.label || column.name}
      </InputLabel>
      <Select
        id={`select-multiple-chip-${column.name}`}
        multiple
        value={filterList[index]}
        renderValue={(selected: any) => selected.join(', ')}
        onChange={(event:any) => {
          filterList[index] = event.target.value;
          onChange(filterList[index], index, column);
        }}
      >
        {filterData[index].map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox
              color="primary"
              checked={filterList[index].indexOf(item) > -1}
            />
            <ListItemText
              primary={
                item === ""
                  ? "(Empty Value)"
                  : item === null || item === undefined
                  ? "(No Value)"
                  : item
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CustomMuiDatatableFilter2 = (
  filterList: MUIDataTableState["filterList"],
  onChange: (
    val: string | string[],
    index: number,
    column: MUIDataTableColumn
  ) => void,
  index: number,
  column: MUIDataTableColumn,
  filterData: MUIDataTableState["filterData"]
) => (
  <FormControl>
    <FormLabel component="legend">{column.label || column.name}</FormLabel>
    <FormGroup aria-label={column.name} row style={{ paddingLeft: 10 }}>
      {filterData[index].map((item: any, i) => (
        <FormControlLabel
          key={`filter-option-${index}-${i}`}
          value={item}
          control={
            <Checkbox
              color="primary"
              checked={filterList[index].indexOf(item) > -1}
              onChange={(event) => {
                if (event.target.checked) {
                  filterList[index] = Array.from(
                    new Set([item, ...filterList[index]])
                  );
                } else {
                  filterList[index] = filterList[index].filter(
                    (v) => v !== item
                  );
                }

                onChange(filterList[index], index, column);
              }}
            />
          }
          label={
            item === ""
              ? "(Empty Value)"
              : item === null || item === undefined
              ? "(No Value)"
              : item === true
              ? "True"
              : item === false
              ? "False"
              : item
          }
          labelPlacement="end"
        />
      ))}
    </FormGroup>
  </FormControl>
);

const createCustomMuiDatatableFilter = (filterSetter?: (newValue: string[]) => void) => { 
  return (
    filterList: MUIDataTableState["filterList"],
    onChange: (
      val: string | string[],
      index: number,
      column: MUIDataTableColumn
    ) => void,
    index: number,
    column: MUIDataTableColumn,
    filterData: MUIDataTableState["filterData"]
  ) => {
    if (filterData[index].length <= 5) {
      return CustomMuiDatatableFilter2(
        filterList,
        onChange,
        index,
        column,
        filterData
      );
    }

    return (
      <FormControl>
        <InputLabel htmlFor={`select-multiple-chip-${column.name}`}>
          {column.label || column.name}
        </InputLabel>
        <Select
          id={`select-multiple-chip-${column.name}`}
          multiple
          value={filterList[index]}
          renderValue={(selected: any) => selected.join(', ')}
          onChange={(event:any) => {
            filterList[index] = event.target.value;

            if(filterSetter){
              const nv = [...filterList[index]];
              filterSetter(nv);
              onChange(nv, index, column);
            } else {
              onChange(filterList[index], index, column);
            }
          }}
        >
          {filterData[index].map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox
                color="primary"
                checked={filterList[index].indexOf(item) > -1}
              />
              <ListItemText
                primary={
                  item === ""
                    ? "(Empty Value)"
                    : item === null || item === undefined
                    ? "(No Value)"
                    : item
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
};

const createCustomMuiDatatableFilter2 = (filterSetter?: (newValue: string[]) => void) => {
  return (
    filterList: MUIDataTableState["filterList"],
    onChange: (
      val: string | string[],
      index: number,
      column: MUIDataTableColumn
    ) => void,
    index: number,
    column: MUIDataTableColumn,
    filterData: MUIDataTableState["filterData"]
  ) => (
    <FormControl>
      <FormLabel component="legend">{column.label || column.name}</FormLabel>
      <FormGroup aria-label={column.name} row style={{ paddingLeft: 10 }}>
        {filterData[index].map((item: any, i) => (
          <FormControlLabel
            key={`filter-option-${index}-${i}`}
            value={item}
            control={
              <Checkbox
                color="primary"
                checked={filterList[index].indexOf(item) > -1}
                onChange={(event) => {
                  if (event.target.checked) {
                    filterList[index] = Array.from(
                      new Set([item, ...filterList[index]])
                    );
                  } else {
                    filterList[index] = filterList[index].filter(
                      (v) => v !== item
                    );
                  }

                  if(filterSetter){
                    const nv = [...filterList[index]];
                    filterSetter(nv);
                    onChange(nv, index, column);
                  } else {
                    onChange(filterList[index], index, column);
                  }
                }}
              />
            }
            label={
              item === ""
                ? "(Empty Value)"
                : item === null || item === undefined
                ? "(No Value)"
                : item === true
                ? "True"
                : item === false
                ? "False"
                : item
            }
            labelPlacement="end"
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const CustomMuiDatatableBooleanFilter = (
  filterList: MUIDataTableState["filterList"],
  onChange: (
    val: string | string[],
    index: number,
    column: MUIDataTableColumn
  ) => void,
  index: number,
  column: MUIDataTableColumn,
  filterData: MUIDataTableState["filterData"]
) => (
  <FormControl>
    <FormLabel component="legend">{column.label || column.name}</FormLabel>
    <FormGroup aria-label={column.name} row style={{ paddingLeft: 10 }}>
      <FormControlLabel
        key={`filter-option-${index}-toggle`}
        value="Yes"
        control={
          <Switch
            checked={filterList[index].indexOf("Yes") > -1}
            onChange={(event) => {
              if (event.target.checked) {
                filterList[index] = ["Yes"];
              } else {
                filterList[index] = ["No"];
              }

              onChange(filterList[index], index, column);
            }}
          />
        }
        label="Yes"
        labelPlacement="end"
      />
    </FormGroup>
  </FormControl>
);

const CustomMuiDatatableBooleanRadioFilter = (
  filterList: MUIDataTableState["filterList"],
  onChange: (
    val: string | string[],
    index: number,
    column: MUIDataTableColumn
  ) => void,
  index: number,
  column: MUIDataTableColumn,
  filterData: MUIDataTableState["filterData"]
) => (
  <FormControl>
    <FormLabel component="legend">{column.label || column.name}</FormLabel>
    <RadioGroup
      row
      aria-label="Status"
      name="StatusSelected"
      onChange={(event) => {
        const newFilter =
          event.target.value === "Both" ? [] : [event.target.value];
        onChange(newFilter, index, column);
      }}
    >
      <FormControlLabel
        checked={filterList[index].indexOf("Yes") > -1}
        value="Yes"
        control={<Radio />}
        label="Yes"
        labelPlacement="end"
      />
      <FormControlLabel
        checked={filterList[index].indexOf("No") > -1}
        value="No"
        control={<Radio />}
        label="No"
        labelPlacement="end"
      />
      <FormControlLabel
        checked={filterList[index].length == 0}
        value="Both"
        control={<Radio />}
        label="Both"
        labelPlacement="end"
      />
    </RadioGroup>
  </FormControl>
);

interface MUIDataTableColumnOptionsFilterConfig {
  filterList?: string[];
  filterType?: FilterType;
  filterOptions?: MUIDataTableFilterOptions;
  customFilterListOptions?: MUIDataTableCustomFilterListOptions;
  /* update?: (filterList: MUIDataTableState["filterList"], filterPos: number, index: number) => MUIDataTableState["filterList"]; */
}

const numberize = (v: any) => {
  const result = Number(
    v === null || v === undefined || v === "" ? 0 : `${v}`.replaceAll(",", "")
  );
  return isNaN(result) ? 0 : result;
};

export const leadsSoldFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-10",
      "11-50",
      "51-100",
      "101-150",
      "151-250",
      "251-500",
      "501-1000",
      "1001-2000",
      "2001-5000",
      "5001-7500",
      "7501-10000",
      "10001+",
    ],
    logic: (sold: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(sold);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const rptFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "0.01-1",
      "1.01-1.50",
      "1.51-2.50",
      "2.51-3.50",
      "3.51-4.50",
      "4.51+",
    ],
    logic: (rpt: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rpt);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const revenueFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-10",
      "11-50",
      "51-100",
      "101-150",
      "151-250",
      "251-500",
      "501-1000",
      "1001-2000",
      "2001-5000",
      "5001-7500",
      "7501-10000",
      "10001-15000",
      "15001+",
    ],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const distributionFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: ["0", "1-10", "11-50", "51-100"],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = Math.round(numberize(rvn) * 100);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const buyerFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  customFilterListOptions: {
    render: (v) => v.map((l: any) => (l && l.toUpperCase()) || "Empty Buyer"),
    update: (filterList, filterPos, index) => {
      filterList[index].splice(filterPos, 1);
      return filterList;
    },
  },
  filterOptions: {
    fullWidth: true,
    logic: (buyer, filters) => {
      if (filters.length) return !filters.includes(buyer);
      return false;
    },
    display: CustomMuiDatatableFilter,
  },
};

/* export const createdDateFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: 'custom',
  filterOptions: {
    fullWidth: true,
    names: [
      'Now - 1m', '1m - 3m', '3m - 6m', '6m - 9m', '9m - 1y', '1y - 1y 3m', '1y 3m - 1y 6m', '1y 6m - 2y', '2y and beyond'
    ],
    logic: (createdDate: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;
      if (!createdDate) return true;

      const now = DateTime.now().startOf("day");
      const dateValue = DateTime.fromISO(createdDate);

      let compare = filterVal.map(val => {
        let showRow = false;

        switch (val) {
          case 'Now - 1m':
            showRow = (dateValue >= now.minus({ months: 1 }) && dateValue <= now);
            break;
          case '1m - 3m':
            showRow = (dateValue >= now.minus({ months: 3 }) && dateValue <= now.minus({ months: 1 }));
            break;
          case '3m - 6m':
            showRow = (dateValue >= now.minus({ months: 6 }) && dateValue <= now.minus({ months: 3 }));
            break;
          case '6m - 9m':
            showRow = (dateValue >= now.minus({ months: 9 }) && dateValue <= now.minus({ months: 6 }));
            break;
          case '9m - 1y':
            showRow = (dateValue >= now.minus({ years: 1 }) && dateValue <= now.minus({ months: 9 }));
            break;
          case '1y - 1y 3m':
            showRow = (dateValue >= now.minus({ years: 1, months: 3 }) && dateValue <= now.minus({ years: 1 }));
            break;
          case '1y 3m - 1y 6m':
            showRow = (dateValue >= now.minus({ years: 1, months: 6 }) && dateValue <= now.minus({ years: 1, months: 3 }));
            break;
          case '1y 6m - 2y':
            showRow = (dateValue >= now.minus({ years: 2 }) && dateValue <= now.minus({ years: 1, months: 6 }));
            break;
          case '2y and beyond':
            showRow = dateValue <= now.minus({ years: 2 });
            break;
        }
        return !showRow;
      });

      // console.log("COMPARE", filterVal, credit, compare, compare.includes(false));
      return !compare.includes(false);
    },
    display: CustomMuiDatatableFilter,
  },
} */

const isNumeric = (value: any) => {
  return /^-?\d+$/.test(value);
};
const isBoolean = (value: any) => {
  return typeof value === "boolean";
};

export const genericFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  customFilterListOptions: {
    render: (v) => {
      return v.map((l: any) =>
        l === null || l === undefined
          ? `null / undefined`
          : isNumeric(l)
          ? l
          : isBoolean(l)
          ? l
            ? "True"
            : "False"
          : l.toUpperCase()
      );
    },
    update: (filterList, filterPos, index) => {
      filterList[index].splice(filterPos, 1);
      return filterList;
    },
  },
  filterOptions: {
    fullWidth: true,
    logic: (value, filters) => {
      if (filters.length) return !filters.includes(value);
      return false;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const userFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  customFilterListOptions: {
    render: (v) => v.map((l: any) => l.toUpperCase()),
    update: (filterList, filterPos, index) => {
      filterList[index].splice(filterPos, 1);
      return filterList;
    },
  },
  filterOptions: {
    fullWidth: true,
    /* names: [
      "Rich", "CORE",
    ], */
    logic: (user, filters) => {
      if (filters.length) {
        /* if (filters.includes("CORE")) {
          let coreFilters = ["CORE", "Core", "core"]
          return !coreFilters.includes(user) ? !filters.includes(user) : !coreFilters.includes(user)
        } */
        return !filters.includes(user);
      }
      return false;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const pingFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-100",
      "101-500",
      "501-1000",
      "1001-5000",
      "5001-10000",
      "10001-13000",
      "13001-17000",
      "17001-20000",
      "20001-25000",
      "25001-50000",
      "50001-75000",
      "75001-100000",
      "100001-140000 ",
      "140001-200000",
      "200001-250000",
      "250001+",
    ],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const pingCapFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-50",
      "51-100",
      "101-500",
      "501-1000",
      "1001-5000",
      "5001-10000",
      "10001-13000",
      "13001-17000",
      "17001-20000",
      "20001-25000",
      "25001-50000",
      "50001-75000",
      "75001-100000",
      "100001+",
    ],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
  },
};

export const activeFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  customFilterListOptions: {
    render: (v: any) => `IsActive: ${v}`,
  },
  filterOptions: {
    fullWidth: true,
    names: ["Yes", "No"],
    logic: (status: any, filters: any[]) => {
      if(filters.length === 0) return false;

      return (!!status && filters.includes("Yes")) || (!status && filters.includes("No")) ? false : true;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const controlledActiveFilter = (filterList: string[], setFilterList: (filterList: string[]) => void): MUIDataTableColumnOptionsFilterConfig => {
  
  return {
    filterType: "custom",
    customFilterListOptions: {
      render: (v: any) => `IsActive: ${v}`,
      /* update: (filterList1, filterPos, index) => {
        filterList1[index] = filterList;
        return filterList1;
      }, */
    },
    filterOptions: {
      fullWidth: true,
      names: ["Yes", "No"],
      logic: (status: any, filters: any) => {
        if (filters[0] == "Yes") {
          return !(status == true);
        }
        if (filters[0] == "No") {
          return !(status == false);
        }
        return false;
      },
      display: createCustomMuiDatatableFilter(setFilterList),
    },
  }
};

export const pingAverageFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-100",
      "101-500",
      "501-1000",
      "1001-2000",
      "2001-3000",
      "3001-4000",
      "4001-5000",
      "5001-6000",
      "6001-7000",
      "8001-9000",
      "9001-10000",
      "10001-20000",
      "20001+",
    ],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const acceptedRateFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-10",
      "11-20",
      "21-30",
      "31-40",
      "41-50",
      "51-60",
      "61-70",
      "71-80",
      "81-90",
      "91-100",
    ],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
  },
};

export const creditBalanceFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  customFilterListOptions: {
    render: (v) => v.map((l: any) => l.toUpperCase()),
    update: (filterList, filterPos, index) => {
      filterList[index].splice(filterPos, 1);
      return filterList;
    },
  },
  filterOptions: {
    fullWidth: true,
    names: [
      "null / not provided",
      "0",
      "0 - 1000",
      "1001 - 5000",
      "5001 - 10000",
      "10001 - 13000",
      "13001 - 15000",
      "15001 - 20000",
      "20001 - 25000",
      "25001 - 50000",
      "50001 - 75000",
      "75001 - 100000",
      "100001 and up",
    ],
    logic: (credit: any, filterVal: any) => {
      if (filterVal.length == 0) return false;

      let compare = filterVal.map((val: any) => {
        let showRow = false;
        switch (val) {
          case "null / not provided":
            showRow = credit == null || credit == "";
            break;
          case "0":
            showRow = parseInt(credit) == 0;
            break;
          case "0 - 1000":
            showRow = parseInt(credit) >= 0 && parseInt(credit) <= 1000;
            break;
          case "1001 - 5000":
            showRow = parseInt(credit) >= 1001 && parseInt(credit) <= 5000;
            break;
          case "5001 - 10000":
            showRow = parseInt(credit) >= 5001 && parseInt(credit) <= 10000;
            break;
          case "10001 - 13000":
            showRow = parseInt(credit) >= 10001 && parseInt(credit) <= 13000;
            break;
          case "15001 - 20000":
            showRow = parseInt(credit) >= 15001 && parseInt(credit) <= 20000;
            break;
          case "20001 - 25000":
            showRow = parseInt(credit) >= 20001 && parseInt(credit) <= 25000;
            break;
          case "25001 - 50000":
            showRow = parseInt(credit) >= 25001 && parseInt(credit) <= 50000;
            break;
          case "50001 - 75000":
            showRow = parseInt(credit) >= 50001 && parseInt(credit) <= 75000;
            break;
          case "75001 - 100000":
            showRow = parseInt(credit) >= 75001 && parseInt(credit) <= 100000;
            break;
          case "100001 and up":
            showRow = parseInt(credit) >= 100001;
            break;
        }
        // console.log(val, credit, !showRow);
        return !showRow;
      });

      // console.log("COMPARE", filterVal, credit, compare, compare.includes(false));
      return !compare.includes(false);
    },
    display: CustomMuiDatatableFilter,
  },
};

export const postAverageFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-100",
      "101-1000",
      "1001-3000",
      "3001-5000",
      "5001-8000",
      "8001-10000",
      "10001-15000",
      "15001+",
    ],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const postCapFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: [
      "0",
      "1-10",
      "11-25",
      "26-50",
      "51-100",
      "101-150",
      "151-250",
      "251-500",
      "501+",
    ],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const createDateFilter = (
  parseFormat: string = ISODateFormat
): MUIDataTableColumnOptionsFilterConfig => {
  return {
    filterType: "custom",
    filterOptions: {
      fullWidth: true,
      names: [
        "Now - 1m",
        "1m - 3m",
        "3m - 6m",
        "6m - 9m",
        "9m - 1y",
        "1y - 1y 3m",
        "1y 3m - 1y 6m",
        "1y 6m - 2y",
        "2y and beyond",
      ],
      logic: (dateString: string, filterVal: any[]) => {
        if (filterVal.length == 0) return false;
        if (!dateString) return true;

        // instance of local date, set to preferred timezone
        const now = DateTime.now().setZone(ServerMomentTimezone);

        // server dates are all UTC, convert to preferred timezone DateTime
        let dateValue = DateTime.fromISO(dateString, {
          zone: ServerMomentTimezone,
        });

        if (!dateValue.isValid) {
          dateValue = DateTime.fromFormat(dateString, parseFormat, {
            zone: ServerMomentTimezone,
          });
        }

        let compare = filterVal.map((val) => {
          let showRow = false;

          switch (val) {
            case "Now - 1m":
              showRow =
                dateValue >= now.minus({ months: 1 }) && dateValue <= now;
              break;
            case "1m - 3m":
              showRow =
                dateValue >= now.minus({ months: 3 }) &&
                dateValue <= now.minus({ months: 1 });
              break;
            case "3m - 6m":
              showRow =
                dateValue >= now.minus({ months: 6 }) &&
                dateValue <= now.minus({ months: 3 });
              break;
            case "6m - 9m":
              showRow =
                dateValue >= now.minus({ months: 9 }) &&
                dateValue <= now.minus({ months: 6 });
              break;
            case "9m - 1y":
              showRow =
                dateValue >= now.minus({ years: 1 }) &&
                dateValue <= now.minus({ months: 9 });
              break;
            case "1y - 1y 3m":
              showRow =
                dateValue >= now.minus({ years: 1, months: 3 }) &&
                dateValue <= now.minus({ years: 1 });
              break;
            case "1y 3m - 1y 6m":
              showRow =
                dateValue >= now.minus({ years: 1, months: 6 }) &&
                dateValue <= now.minus({ years: 1, months: 3 });
              break;
            case "1y 6m - 2y":
              showRow =
                dateValue >= now.minus({ years: 2 }) &&
                dateValue <= now.minus({ years: 1, months: 6 });
              break;
            case "2y and beyond":
              showRow = dateValue <= now.minus({ years: 2 });
              break;
          }
          return !showRow;
        });

        // console.log("COMPARE", filterVal, credit, compare, compare.includes(false));
        return !compare.includes(false);
      },
      display: CustomMuiDatatableFilter,
    },
  };
};

export const stringColumnFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  customFilterListOptions: {
    render: (v) =>
      v.map((l: any) =>
        convertString.isEmpty(l) ? "(No Value)" : l.toUpperCase()
      ),
    update: (filterList, filterPos, index) => {
      filterList[index].splice(filterPos, 1);
      return filterList;
    },
  },
  filterOptions: {
    fullWidth: true,
    logic: (value, filters) => {
      if (filters.length) return !filters.includes(value);
      return false;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const postRejectedFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: ["0", "1", "2", "3", "4", "5+"],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter2,
  },
};

export const numberColumnFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  customFilterListOptions: {
    update: (filterList, filterPos, index) => {
      filterList[index].splice(filterPos, 1);
      return filterList;
    },
  },
  filterOptions: {
    fullWidth: true,
    logic: (value, filters) => {
      if (filters.length) return !filters.includes(value);
      return false;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const booleanToggleFilter = (
  fieldName: string = "Status"
): MUIDataTableColumnOptionsFilterConfig => ({
  filterType: "custom",
  customFilterListOptions: {
    render: (v: any) => `${fieldName}: ${v}`,
  },
  filterOptions: {
    fullWidth: true,
    names: ["Yes", "No"],
    logic: (status: any, filters: any) => {
      if (filters[0] == "Yes") {
        return !(status == true);
      }
      if (filters[0] == "No") {
        return !(status == false);
      }
      return false;
    },
    display: CustomMuiDatatableBooleanFilter,
  },
});

export const booleanRadioFilter = (
  fieldName: string = "Status"
): MUIDataTableColumnOptionsFilterConfig => ({
  filterType: "custom",
  customFilterListOptions: {
    render: (v: any) => `${fieldName}: ${v}`,
  },
  filterOptions: {
    fullWidth: true,
    names: ["Yes", "No"],
    logic: (status: any, filters: any) => {
      if (filters[0] == "Yes") {
        return !(status == true);
      }
      if (filters[0] == "No") {
        return !(status == false);
      }

      return false;
    },
    display: CustomMuiDatatableBooleanRadioFilter,
  },
});

export const buyerCapFilter: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",
  filterOptions: {
    fullWidth: true,
    names: ["null", "1-100", "101-500", "501-1000", "1001-5000", "5001+"],
    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);
      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },
    display: CustomMuiDatatableFilter,
  },
};

export const numberColumnFilterNullTo100K: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",

  filterOptions: {
    fullWidth: true,

    names: [
      "0 / null",
      "1-100",
      "101-500",
      "501-1000",
      "1001-5000",
      "5001-100000",
      "100000+",
    ],

    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);

      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },

    display: CustomMuiDatatableFilter,
  },
};

export const numberColumnFilterNullTo1000K: MUIDataTableColumnOptionsFilterConfig = {
  filterType: "custom",

  filterOptions: {
    fullWidth: true,

    names: [
      "0 / null",
      "1-100",
      "101-500",
      "501-1000",
      "1001-5000",
      "5001-1000000",
      "1000000+",
    ],

    logic: (rvn: string, filterVal: any[]) => {
      if (filterVal.length == 0) return false;

      const numVal = numberize(rvn);

      let showRow = false;

      filterVal.forEach((val) => {
        showRow = showRow || valueIsWithinRange(val, numVal);
      });

      return !showRow;
    },

    display: CustomMuiDatatableFilter,
  },
};

export default CustomMuiDatatableFilter;
